button.nav-link {
  background-color: transparent;
  border: 0;
}
button.nav-link:hover,
button.nav-link:focus,
button.nav-link:active {
  text-decoration: none;
  outline: 0;
}

.collapse {
  /* display: block!important; */
  visibility: unset !important;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.collapse.show {
  max-height: 99em;
  transition: max-height 0.5s ease-in-out;
}
