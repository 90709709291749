* {
  outline: green 1px solid transparent;
}

.table-nostriped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100%;
}


/* contract-modal-styles */
::-webkit-input-placeholder {
    color: #858796;
    opacity: 1;
  }

  
  ::-moz-placeholder {
    color: #858796;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #858796;
    opacity: 1;
  }
  
  ::-ms-input-placeholder {
    color: #858796;
    opacity: 1;
  }
  
  ::placeholder {
    color: #858796;
    opacity: 1;
  }